exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-contactanos-jsx": () => import("./../../../src/pages/contactanos.jsx" /* webpackChunkName: "component---src-pages-contactanos-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-nuestra-marca-jsx": () => import("./../../../src/pages/nuestra-marca.jsx" /* webpackChunkName: "component---src-pages-nuestra-marca-jsx" */),
  "component---src-pages-que-tipo-de-piel-soy-index-jsx": () => import("./../../../src/pages/que-tipo-de-piel-soy/index.jsx" /* webpackChunkName: "component---src-pages-que-tipo-de-piel-soy-index-jsx" */),
  "component---src-pages-que-tipo-de-piel-soy-iniciar-test-jsx": () => import("./../../../src/pages/que-tipo-de-piel-soy/iniciar-test.jsx" /* webpackChunkName: "component---src-pages-que-tipo-de-piel-soy-iniciar-test-jsx" */),
  "component---src-pages-terminos-y-condiciones-index-jsx": () => import("./../../../src/pages/terminos-y-condiciones/index.jsx" /* webpackChunkName: "component---src-pages-terminos-y-condiciones-index-jsx" */),
  "component---src-templates-test-result-test-result-jsx": () => import("./../../../src/templates/test-result/test-result.jsx" /* webpackChunkName: "component---src-templates-test-result-test-result-jsx" */)
}

