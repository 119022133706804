module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"pathToEmotionCacheProps":""},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WP3FNX2","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pomys","short_name":"Pomys","description":"Limpia y cuida tu piel con POMYS. Con extractos naturales. Limpieza facial para todo tipo de piel. Rueditas Desmaquilladoras. Cuidado Facial. Según tu tipo de piel. Toallitas Húmedas. Agua Micelar. Tipos: Toallitas Húmedas, Agua Micelar, Rueditas Desmaquilladoras.","start_url":"/","background_color":"#7350D1","theme_color":"#7350D1","display":"standalone","icon":"src/shared/assets/images/favicon-v2.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"74a361c27bc795a2d5fdeb63467c3d08"},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.pomys.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
